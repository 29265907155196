import route$47$app$46$route$46$js from './route/app.route.js'
import route$47$forgot$46$route$46$js from './route/forgot.route.js'
import route$47$login$46$route$46$js from './route/login.route.js'
import route$47$signup$46$route$46$js from './route/signup.route.js'
import route$47$app$47$list$46$route$46$js from './route/app/list.route.js'
import route$47$app$47$list$47$$91$listId$93$$46$route$46$js from './route/app/list/[listId].route.js'
import route$47$app$47$list$47$$91$listId$93$$47$todo$46$route$46$js from './route/app/list/[listId]/todo.route.js'
import route$47$app$47$list$47$$91$listId$93$$47$todo$47$$91$todoId$93$$46$route$46$js from './route/app/list/[listId]/todo/[todoId].route.js'

export default [
	{ path: 'route/app.route.js', export: route$47$app$46$route$46$js },
	{ path: 'route/forgot.route.js', export: route$47$forgot$46$route$46$js },
	{ path: 'route/login.route.js', export: route$47$login$46$route$46$js },
	{ path: 'route/signup.route.js', export: route$47$signup$46$route$46$js },
	{ path: 'route/app/list.route.js', export: route$47$app$47$list$46$route$46$js },
	{ path: 'route/app/list/[listId].route.js', export: route$47$app$47$list$47$$91$listId$93$$46$route$46$js },
	{ path: 'route/app/list/[listId]/todo.route.js', export: route$47$app$47$list$47$$91$listId$93$$47$todo$46$route$46$js },
	{ path: 'route/app/list/[listId]/todo/[todoId].route.js', export: route$47$app$47$list$47$$91$listId$93$$47$todo$47$$91$todoId$93$$46$route$46$js }
]
